:root {
  --client-color-h: 209;
  --client-color-s: 100%;
  --client-color-l: 10%;
  --search-color-h: 212;
  --search-color-s: 26%;
  --search-color-l: 69%;
  --greeting-background: url("../../assets/App Background.svg");
}

$client-color: hsl(
  var(--client-color-h),
  var(--client-color-s),
  var(--client-color-l)
);
$search-color: hsl(
  var(--search-color-h),
  var(--search-color-s),
  var(--search-color-l)
);

$warn-color: #fcba03;

$primary-gradient: linear-gradient(90deg, $search-color, $client-color);

$light-color: #b6b6b6;

$background-color: #f3f4f5;

@mixin shadow {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

@mixin high-contrast-colors {
  color: black !important;
  background-color: white !important;
}

@mixin text-overflow-ellipsis {
  white-space: nowrap; // Ensure the text stays on one line
  overflow: hidden; // Hide overflow
  text-overflow: ellipsis; // Add ellipsis for overflow
}

@mixin notification-bubble {
  background-color: $client-color;
  color: white;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;

  mat-icon {
    font-size: 0.7rem;
    width: 0.7rem;
    height: 0.7rem;
    line-height: 0.7rem;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
