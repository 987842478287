body.high-contrast {
  --client-color-h: 0 !important;
  --client-color-s: 0% !important;
  --client-color-l: 0% !important;
  --search-color-h: 0 !important;
  --search-color-s: 0% !important;
  --search-color-l: 0% !important;

  mat-icon,
  .mat-paginator-range-label,
  .mat-header-cell
  {
    background: white !important;
    color: black !important;
  }
}
